<template>
<Calendar :widget="widget" :data="data" :editable="true" />
</template>

<script>
// این ویو برای تقویم اقتصادی استفاده میشود

import Calendar from '@/components/CalendarView.vue'

export default {
    name: 'CalendarView',
    components: {
        Calendar
    },
    data: function () {
        return {
            widget: {},
            data: {},
        }
    },
    mounted() {
        // در متد مانت یک فانکشی رو از هلپر کال میکنیم جهت لود کردن داده های ویجت 
        this.$helpers.getWidgets({ widget_type: 'calendar', store: false }).then((widgets) => {
            let widget = widgets[0];

            this.widget = widget.widget;
            this.data = widget.data;
        });
    },
}
</script>
